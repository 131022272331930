import React from "react"
import styled from "styled-components"

import Milkshake from "./Milkshake"
import Smoothie from "./Smoothie"

import ThemeContextConsumer from "../../../data/ThemeContext"

const Wrapper = styled.div`
  pointer-events: none;

  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media (max-width: 900px) {
    overflow: hidden;
  }

  /* box-shadow: 0 0 0 1px dodgerblue inset; */
`

const SplashWrapper = styled.div`
  position: relative;

  height: 550px;

  margin-left: 20vw;

  /* box-shadow: 0 0 0 1px lime inset; */

  @media (max-width: 900px) {
    margin-top: 60px;
    margin-left: auto;
    margin-right: 0;
    width: 300px;
    height: auto;
  }

  @media (max-width: 640px) {
    margin-top: 100px;
    width: 240px;
    height: auto;
  }
`

export default function CupSplash(props) {
  return (
    <Wrapper>
      <SplashWrapper>
        <ThemeContextConsumer>
          {({ data, set }) => (
            <>
              {data.themeName === "milkshakes" && <Milkshake />}
              {data.themeName === "smoothies" && <Smoothie />}
            </>
          )}
        </ThemeContextConsumer>
      </SplashWrapper>
    </Wrapper>
  )
}
